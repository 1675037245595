<template>
  <div class="card">
    <div class="card-body p-0" style="hight: 20px">
      <table
        style="font-size: 9px; overflow-x: auto"
        id="turnos"
        class="
          table table-bordered table-striped table-hover table-sm
          team-roster-table
          table-responsive
        "
      >
        <thead align="center">
          <tr>
            <th>
              <!-- <div>
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  data-toggle="modal"
                  data-target="#modal-form-validate-viaje"
                  style="cursor: pointer"
                  v-if="
                    (($parent.procesoCrearViaje &&
                      $parent.filtros.tipo_control == 1) ||
                      ($parent.procesoCrearViajeRegTerc &&
                        $parent.filtros.tipo_control == 3)) &&
                    $parent.filtros.tipo_control &&
                    $parent.filtros.bloque_id
                  "
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div> -->
            </th>
            <th>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="filtros.idTurno"
                placeholder="#Turno"
                @keyup.enter="$parent.getIndex()"
                :disabled="$parent.filtros.tipo_control != 1"
              />
            </th>
            <th v-if="$parent.filtros.tipo_control == 1" class="text-center">
              Operación
            </th>
            <th v-if="$parent.filtros.tipo_control == 1" class="text-center">
              Bloque
            </th>
            <th v-if="$parent.filtros.tipo_control == 1" class="text-center">
              Tipo Tarifa
            </th>
            <th colspan="2">
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="filtros.nCabezote"
                placeholder="Vehículo"
                @keyup.enter="$parent.getIndex()"
              />
            </th>
            <th>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="filtros.nRemolque"
                placeholder="Remolque"
                @keyup.enter="$parent.getIndex()"
              />
            </th>
            <th>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="filtros.nConductor"
                placeholder="Conductor"
                @keyup.enter="$parent.getIndex()"
              />
            </th>
            <th>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="filtros.nConductor2"
                placeholder="Conductor 2"
                @keyup.enter="$parent.getIndex()"
              />
            </th>
            <th>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="filtros.nEmpresa"
                placeholder="Empresa"
                @keyup.enter="$parent.getIndex()"
              />
            </th>
            <th v-if="$parent.filtros.tipo_control == 1">
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="filtros.ruta"
                placeholder="Ruta"
                @keyup.enter="$parent.getIndex()"
              />
            </th>
            <th>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="filtros.producto"
                placeholder="Producto"
                @keyup.enter="$parent.getIndex()"
              />
            </th>
            <th>Fecha Inicio</th>
            <th>Fecha Fin</th>
            <th v-if="$parent.filtros.tipo_control == 1">
              <select
                class="form-control form-control-sm"
                v-model="filtros.estado"
                @change="$parent.getIndex()"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in objLista.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </th>
            <th v-else>
              <select
                class="form-control form-control-sm"
                v-model="filtros.estado"
                @change="$parent.getIndex()"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in objLista.estadosPostulacion"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in turnos.data" :key="item.id">
            <td>
              <!-- Split dropright button -->
              <div
                class="btn-group"
                :class="turnos.data.length == 1 ? 'dropop' : 'dropright'"
                style="position: static; z-index: auth"
              >
                <button
                  type="button"
                  class="btn btn-dark btn-sm"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-align-justify"></i>
                </button>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-cambio-conductor"
                    style="cursor: pointer"
                    @click="llenarModalConductor(item)"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.cambiarConductor'
                      ) && $parent.filtros.tipo_control == 1
                    "
                  >
                    <i class="fas fa-exchange-alt"></i>
                    Cambiar Conductor
                  </a>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-cambio-vehiculo"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.cambiarVehiculo'
                      ) && $parent.filtros.tipo_control == 1
                    "
                    @click="llenarModalVehiculo(item)"
                  >
                    <i class="fas fa-exchange-alt"></i>
                    Cambiar Vehículo
                  </a>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-cambio-vehiculo-turno"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.cambiarVehiculoTurno'
                      ) &&
                        $parent.filtros.tipo_control == 1 &&
                        (item.estado == 8 || item.estado == 7)
                    "
                    @click="llenarModalVehiculoTurno(item)"
                  >
                    <i class="fas fa-exchange-alt"></i>
                    Cambiar Turno/Vehículo
                  </a>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-cambio-remolque"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.cambiarRemolque'
                      ) &&
                        $parent.filtros.tipo_control == 1 &&
                        item.vehiculo.tipo_vehiculo_id == 1
                    "
                    @click="llenarModalRemolque(item)"
                  >
                    <i class="fas fa-exchange-alt"></i>
                    Cambiar Remolque
                  </a>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-cambio-operacion"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.cambiarOperacion'
                      ) && $parent.filtros.tipo_control == 1
                    "
                    @click="llenarModalOperacion(item)"
                  >
                    <i class="fas fa-exchange-alt"></i>
                    Cambiar Operación
                  </a>

                  <!-- Cambiar Horas -->
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-horas-turno"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.cambioHoras'
                      )
                    "
                    @click="
                      llenarModalCambiarHorasTurno(
                        item.id,
                        8,
                        item.fecha_ini,
                        item.fecha_fin
                      )
                    "
                  >
                    <i class="fas fa-exchange-alt"></i>
                    Cambiar Horas
                  </a>

                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-subir-tramas"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.cambiarOperacion'
                      ) &&
                        $parent.filtros.tipo_control == 1 &&
                        item.tif_tramas_pendientes.length == 0
                    "
                    @click="llenarModalTramas(item)"
                  >
                    <i class="fas fa-user-edit"></i>
                    Petición Tramas
                  </a>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-finalizacion-turno"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.finalizacionTurno'
                      ) && item.estado < 9
                    "
                    @click="
                      llenarModalFinalizacionTurno(
                        item.id,
                        item.estado,
                        8,
                        'Finalizacion turno',
                        item.fecha_fin,
                        item.fecha_ini
                      )
                    "
                  >
                    <i class="fas fa-lock"></i>
                    Finalizacion turno
                  </a>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-mapa"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.generarMapa'
                      ) && $parent.filtros.tipo_control == 1
                    "
                    @click="llenarModalMapa(item)"
                  >
                    <i class="fa fa-map-marker-alt"></i>
                    Mapa
                  </a>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#modal-form-mapa"
                    style="cursor: pointer"
                    v-if="
                      $store.getters.can(
                        'tif.ventanaGeneralOperaciones.generarMapa'
                      ) && $parent.filtros.tipo_control == 2
                    "
                    @click="llenarModalMapa(item, item.solicitud.id)"
                  >
                    <i class="fa fa-map-marker-alt"></i>
                    Mapa
                  </a>
                </div>
              </div>
            </td>
            <td v-if="$parent.filtros.tipo_control == 1" align="center">
              <button
                type="button"
                class="btn btn-sm btn-default py-0"
                data-toggle="modal"
                data-target="#modal-form-detTurno"
                @click="llenarModalDetTurno(item.id)"
              >
                {{ item.id }}
              </button>
            </td>
            <td v-else align="center">
              <div v-if="item.tif_turno_id != null">
                <button
                  type="button"
                  class="btn btn-sm btn-default py-0"
                  data-toggle="modal"
                  data-target="#modal-form-detTurno"
                  @click="llenarModalDetTurno(item.tif_turno_id)"
                >
                  {{ item.tif_turno_id }}
                </button>
              </div>
              <div v-else>
                <span class="badge badge-danger">Sin Asignar</span>
              </div>
            </td>
            <td v-if="$parent.filtros.tipo_control == 1">
              {{ item.operacion.Noperacion }}
            </td>
            <td v-if="$parent.filtros.tipo_control == 1">
              {{ item.bloque.nombre }}
            </td>
            <td v-if="$parent.filtros.tipo_control == 1">
              {{ item.NtipoTarifa }}
            </td>
            <td colspan="1" v-if="item.vehiculo != null">
              {{ item.vehiculo.placa }}
            </td>
            <td class="text-center" colspan="1">
              <div
                v-if="
                  item.vehiculo.ultimo_registro &&
                    item.vehiculo.ultimo_registro !== undefined
                "
              >
                <i
                  v-if="
                    item.vehiculo.ultimo_registro.fecha_hora_ultimo_reporte <
                      fechaCorte
                  "
                  class="fa fa-times-circle text-danger"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    item.vehiculo.ultimo_registro.fecha_hora_ultimo_reporte
                  "
                ></i>

                <i
                  v-else
                  class="fa fa-check text-success"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    item.vehiculo.ultimo_registro.fecha_hora_ultimo_reporte
                  "
                ></i>
              </div>
              <div v-else>
                <i
                  class="fa fa-times-circle text-danger"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Sin fecha de reporte"
                ></i>
              </div>
            </td>
            <td v-if="item.remolque != null">
              {{ item.remolque.placa }}
            </td>
            <td v-else></td>
            <td>
              {{ item.conductor.nombres }} {{ item.conductor.apellidos }}
              <span class="badge badge-info">
                {{ item.conductor.numero_documento }}
              </span>
            </td>
            <td v-if="item.conductor2 != null">
              {{ item.conductor2.nombres }} {{ item.conductor2.apellidos }}
              <span class="badge badge-info">
                {{ item.conductor2.numero_documento }}
              </span>
            </td>
            <td v-else>
              <span class="badge badge-danger">Sin Asignar</span>
            </td>
            <td
              v-if="item.empresa != null && $parent.filtros.tipo_control == 1"
            >
              {{ item.empresa.razon_social }}
            </td>
            <td v-else>{{ item.solicitud.empresa.razon_social }}</td>
            <td v-if="item.ruta != null && $parent.filtros.tipo_control == 1">
              {{ item.ruta.nombre }}
            </td>
            <td
              v-if="item.producto != null && $parent.filtros.tipo_control == 1"
            >
              {{ item.producto.nombre }}
            </td>
            <td v-else>{{ item.det_solicitud.producto.nombre }}</td>
            <td>
              {{ item.fecha_ini }}
            </td>
            <td>
              {{ item.fecha_fin }}
            </td>
            <td v-if="$parent.filtros.tipo_control == 1" class="text-center">
              <span
                class="badge"
                :class="
                  item.estado == 1
                    ? 'badge-success'
                    : item.estado == 2 || item.estado == 3 || item.estado == 4
                    ? 'badge-info'
                    : item.estado == 5
                    ? 'badge-warning'
                    : item.estado == 6 || item.estado == 11
                    ? 'badge-danger'
                    : item.estado == 7
                    ? 'badge-primary'
                    : item.estado == 8
                    ? 'badge-dark'
                    : item.estado == 9
                    ? 'bg-green'
                    : item.estado == 10
                    ? 'badge-light'
                    : ''
                "
              >
                {{ item.estado }}. {{ item.estadoTurno }}
              </span>
            </td>
            <td v-else align="center">
              <span
                class="badge"
                :class="
                  item.estado == 1 || item.estado == 2
                    ? 'badge-info'
                    : 'badge-danger'
                "
              >
                {{ item.estado }}. {{ item.estadoDetpos }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <div class="float-left" v-if="turnos.total">
        <p>
          Mostrando del <b>{{ turnos.from }}</b> al <b>{{ turnos.to }}</b> de un
          total: <b>{{ turnos.total }}</b> Registros
        </p>
      </div>
      <div class="float-left" v-else>
        <p>
          <span class="badge badge-dark">No hay registros para mostrar</span>
        </p>
      </div>
      <pagination
        :data="turnos"
        @pagination-change-page="$parent.getIndex"
        :limit="5"
        align="right"
      ></pagination>
    </div>
  </div>
</template>

<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
import moment from "moment";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "TifventanaGeneralOperacionesTurnos",
  components: {
    pagination,
  },
  directives: {
    "b-popover": VBPopover,
  },
  data() {
    return {
      form: {
        vehiculo: null,
        remolque: null,
      },
      turnos: {},
      filtros: {},
      fechaAct: null,
      objLista: {
        estados: [],
        estadosPostulacion: [],
      },
      sitio_id: null,
    };
  },

  methods: {
    fechaActual() {
      this.fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
      this.fechaCorte = moment(this.fechaAct)
        .add(-1, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    },

    async llenarModalVehiculo(turno) {
      await axios({
        method: "GET",
        url: "/api/tif/ventanasGeneralOperaciones/validarCambio",
        params: { turno_id: turno.id, tipo_autorizacion: 9 },
      }).then(async (response) => {
        if (response.data.length > 0) {
          if (response.data[0].det_autorizacion.length > 0) {
            await this.$swal({
              title: "No se puede realizar esta acción",
              text:
                "Existe una solicitud de cambio de vehículo pendiente por autorizar",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "OK",
            });
            await this.$parent.$refs.TifVentanaGeneralOperacionesCambios.$refs.closeModal1.click();
          }
        } else {
          this.$parent.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_vehiculo(
            turno
          );
        }
      });
    },

    async llenarModalVehiculoTurno(turno) {
      await axios({
        method: "GET",
        url: "/api/tif/ventanasGeneralOperaciones/validarCambio",
        params: { turno_id: turno.id, tipo_autorizacion: 13 },
      }).then(async (response) => {
        if (response.data.length > 0) {
          if (response.data[0].det_autorizacion.length > 0) {
            await this.$swal({
              title: "No se puede realizar esta acción",
              text:
                "Existe una solicitud de cambio de turno de vehículo pendiente por autorizar",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "OK",
            });
            await this.$parent.$refs.TifVentanaGeneralOperacionesCambios.$refs.closeModal1.click();
          }
        } else {
          this.$parent.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_vehiculo_turno(
            turno
          );
        }
      });
    },

    async llenarModalRemolque(turno) {
      await axios({
        method: "GET",
        url: "/api/tif/ventanasGeneralOperaciones/validarCambio",
        params: { turno_id: turno.id, tipo_autorizacion: 10 },
      }).then(async (response) => {
        if (response.data.length > 0) {
          if (response.data[0].det_autorizacion.length > 0) {
            await this.$swal({
              title: "No se puede realizar esta acción",
              text:
                "Existe una solicitud de cambio de remolque pendiente por autorizar",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "OK",
            });
            await this.$parent.$refs.TifVentanaGeneralOperacionesCambios.$refs.closeModal2.click();
          }
        } else {
          this.$parent.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_remolque(
            turno
          );
        }
      });
    },

    async llenarModalConductor(turno) {
      await axios({
        method: "GET",
        url: "/api/tif/ventanasGeneralOperaciones/validarCambio",
        params: { turno_id: turno.id, tipo_autorizacion: 11 },
      }).then(async (response) => {
        if (response.data.length > 0) {
          if (response.data[0].det_autorizacion.length > 0) {
            await this.$swal({
              title: "No se puede realizar esta acción",
              text:
                "Existe una solicitud de cambio de conductor pendiente por autorizar",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "OK",
            });
            await this.$parent.$refs.TifVentanaGeneralOperacionesCambios.$refs.closeModal4.click();
          }
        } else {
          this.$parent.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_conductor(
            turno
          );
        }
      });
    },

    async llenarModalOperacion(turno) {
      await axios({
        method: "GET",
        url: "/api/tif/ventanasGeneralOperaciones/validarCambio",
        params: { turno_id: turno.id, tipo_autorizacion: 12 },
      }).then(async (response) => {
        if (response.data.length > 0) {
          if (response.data[0].det_autorizacion.length > 0) {
            await this.$swal({
              title: "No se puede realizar esta acción",
              text:
                "Existe una solicitud de cambio de operación pendiente por autorizar",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "OK",
            });
            await this.$parent.$refs.TifVentanaGeneralOperacionesCambios.$refs.closeModal3.click();
          }
        } else {
          this.$parent.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_operacion(
            turno
          );
        }
      });
    },

    llenarModalCambiarHorasTurno(
      id,
      mensaje,
      fecha_ini,
      fecha_fin,
    ) {
      this.$parent.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_cambiar_horas_turno(
        id,        
        mensaje,
        fecha_ini,
        fecha_fin
      );
    },

    llenarModalTramas(turno) {
      this.$parent.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_tramas(
        turno
      );
    },

    llenarModalMapa(item, solicitud_id) {
      this.$parent.$refs.TifVentanaGeneralOperacionesMapa.llenar_modal_mapa(
        item,
        solicitud_id
      );
    },

    llenarModalDetTurno(turno) {
      this.$parent.$refs.TifTurnoDet.llenar_modal_detTurno(turno);
    },

    llenarModalFinalizacionTurno(
      id,
      estado,
      estadoFinalizacion,
      mensaje,
      fecha_fin,
      fecha_ini
    ) {
      this.$parent.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_finalizacion_turno(
        id,
        estado,
        estadoFinalizacion,
        mensaje,
        fecha_fin,
        fecha_ini
      );
    },

    getEstados() {
      axios.get("/api/lista/103").then((response) => {
        this.objLista.estados = response.data;
      });
    },

    getEstadosPostulacion() {
      axios.get("/api/lista/107").then((response) => {
        this.objLista.estadosPostulacion = response.data;
      });
    },
  },

  mounted() {
    this.fechaActual();
    this.getEstados();
    this.getEstadosPostulacion();
  },
};
</script>

<template>
  <div class="hold-transition">
    <div
      class="modal-backdrop fade show"
      style="z-index: 10000"
      v-if="cargando"
    >
      <div class="row justify-content-center h-100">
        <div class="align-self-center">
          <ScaleLoader
            class="text-center"
            color="#fff"
            :height="150"
            :width="10"
            margin="10px"
          />
          <h3 class="text-center text-light">Cargando...</h3>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ventanas Generales Operaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Ventana General</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <label for="bloques">Bloque</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="slct_bloque"
                      placeholder="Bloques"
                      label="nombre"
                      :options="bloques"
                      :filterable="true"
                      :clearable="true"
                      @input="buildProcess()"
                      multiple
                    ></v-select>
                    <div class="error" v-show="!filtros.bloque_id">
                      Ingrese un bloque
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="listaConsulta">Tipo Consulta</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.tipo_control"
                        @change="getIndex()"
                      >
                        <option
                          v-for="listaConsulta in listasForms.listaConsultas"
                          :key="listaConsulta.numeracion"
                          :value="listaConsulta.numeracion"
                        >
                          {{ listaConsulta.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="col-md-3 mt-4"
                    v-if="filtros.tipo_control && filtros.bloque_id"
                  >
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="getIndex()"
                    >
                      <i class="fas fa-sync"></i>
                    </button>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="tab-Turnos"
                          data-toggle="tab"
                          href="#Turnos"
                          >Turnos</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab" id="Turnos">
                        <TifVentanaGeneralOperacionesTurnos
                          ref="TifVentanaGeneralOperacionesTurnos"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TifVentanaGeneralOperacionesCambios
          ref="TifVentanaGeneralOperacionesCambios"
        />
        <TifVentanaGeneralOperacionesMapa
          ref="TifVentanaGeneralOperacionesMapa"
        />
        <TifTurnoDet ref="TifTurnoDet" />
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import { ScaleLoader } from "@saeris/vue-spinners";
import TifVentanaGeneralOperacionesTurnos from "./TifVentanaGeneralOperacionesTurnos";
import TifVentanaGeneralOperacionesCambios from "./TifVentanaGeneralOperacionesCambios";
import TifVentanaGeneralOperacionesMapa from "./TifVentanaGeneralOperacionesMapa";
import TifTurnoDet from "../turnos/TifTurnoDet";

export default {
  name: "TifVentanaGeneralOperacionesIndex",
  components: {
    ScaleLoader,
    TifVentanaGeneralOperacionesTurnos,
    TifVentanaGeneralOperacionesCambios,
    TifVentanaGeneralOperacionesMapa,
    TifTurnoDet,
    vSelect,
  },
  data() {
    return {
      filtros: {
        bloque_id: null,
        tipo_control: null,
      },
      cargando: false,
      listasForms: {
        listaConsultas: [],
      },
      slct_punto: [],
      puntos: [],
      slct_bloque: [],
      bloques: [],
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.$refs.TifVentanaGeneralOperacionesTurnos.turnos = {};
      this.filtros.nCabezote = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.nCabezote;
      this.filtros.idTurno = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.idTurno;
      this.filtros.nRemolque = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.nRemolque;
      this.filtros.nConductor = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.nConductor;
      this.filtros.nConductor2 = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.nConductor2;
      this.filtros.nEmpresa = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.nEmpresa;
      this.filtros.ruta = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.ruta;
      this.filtros.producto = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.producto;
      this.filtros.estado = this.$refs.TifVentanaGeneralOperacionesTurnos.filtros.estado;
      if (this.filtros.bloque_id && this.filtros.tipo_control) {
        let me = this;
        axios
          .get("/api/tif/ventanasGeneralOperaciones/index?page=" + page, {
            params: this.filtros,
          })
          .then((response) => {
            me.$refs.TifVentanaGeneralOperacionesTurnos.turnos = response.data;
            me.cargando = false;
            this.$router.replace({
              path: `/Tif/VentanaGeneralOperaciones/`,
            });
          });
      }
    },

    async getListaConsulta() {
      await axios.get("/api/lista/47").then((response) => {
        this.listasForms.listaConsultas = response.data;
        this.listasForms.listaConsultas.splice(2, 1);
      });
    },

    async buscarBloques() {
      let me = this;
      var url = "api/admin/bloques/lista?estado=1";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.bloques = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buildProcess() {
      if (this.slct_bloque) {
        this.filtros.bloque_id = this.slct_bloque.map((e) => e.id);
      } else {
        this.filtros.bloque_id = null;
        this.$refs.TifVentanaGeneralOperacionesTurnos.turnos = {};
      }
    },
  },
  async mounted() {
    await this.getListaConsulta();
    await this.buscarBloques();

    if (this.$route.params.bloque) {
      this.filtros.tipo_control = 1;
      this.filtros.slct_bloque = {
        id: this.$route.params.bloque_id,
        nombre: this.$route.params.bloque,
      };
      await this.getIndex();
    }

    if (this.$route.params.bloque_id && this.$route.params.tipo_control) {
      this.filtros.bloque_id = this.$route.params.bloque_id;
      this.filtros.tipo_control = this.$route.params.tipo_control;

      let bloque_n = await this.bloques.find(
        (n) => n.id === parseInt(this.filtros.bloque_id)
      );
      this.slct_bloque = bloque_n;

      await this.getIndex();

      await this.buildProcess();
    }
  },
};
</script>

<template>
  <div>
    <!-- Modal Cambio Vehiculo-->
    <div
      class="modal fade"
      id="modal-form-cambio-vehiculo"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Vehículo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehículo">Vehículo Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="vehiculo"
                    readonly
                    v-model="form.vehiculoPlacaAnt"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehículo">Vehículo Sustituye</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="placa"
                    v-model="form.vehiculoPlacaNueva"
                    :class="[
                      $v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    @change="buscarCabezote()"
                  />
                  <div class="error" v-if="!$v.form.vehiculo.required">
                    Ingrese una Placa
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-dark"
              v-show="
                !$v.form.justificacion.$invalid && !$v.form.vehiculo.$invalid
              "
              @click="cambioVehiculo()"
            >
              <i class="fas fa-marker"> Realizar Cambio</i>
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Modal Cambio Remolque-->
    <div
      class="modal fade"
      id="modal-form-cambio-remolque"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Remolque</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal2"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="remolque">Remolque Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="remolque"
                    readonly
                    v-model="form.remolquePlacaAnt"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="remolque">Remolque Sustituye</label>
                  <input
                    type="text"
                    id="remolque"
                    class="form-control form-control-sm"
                    v-model="form.remolqueNuevo"
                    :class="[
                      $v.form.remolque.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    @change="buscarRemolque()"
                  />
                  <div class="error" v-if="!$v.form.remolque.required">
                    Ingrese una Placa
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="
                !$v.form.justificacion.$invalid && !$v.form.remolque.$invalid
              "
              @click="cambioRemolque()"
            >
              Realizar Cambio
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Modal Cambio Operacion-->
    <div
      class="modal fade"
      id="modal-form-cambio-operacion"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Operación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal3"
              @click="limpiar()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="bloque">Bloque</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="bloque"
                    readonly
                    v-model="form.bloque"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="operacion">Operación actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="operacion"
                    readonly
                    v-model="form.operacionAnt"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="operacion">Ruta</label>
                  <v-select
                    :class="[
                      $v.form.ruta_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="ruta"
                    placeholder="Ruta"
                    label="nombre"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.rutas"
                    :filterable="false"
                    @search="buscarRutas"
                    @input="selectRuta()"
                  ></v-select>
                  <div class="error" v-if="!$v.form.ruta_id.required">
                    Ingrese una ruta
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="operacion">Operación sustituye</label>
                  <v-select
                    :class="[
                      $v.form.operacion_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="operacion"
                    placeholder="Operación"
                    label="Noperacion"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.operaciones"
                    :filterable="false"
                    @search="buscarOperaciones"
                    @input="selectOperacion()"
                  ></v-select>
                  <div class="error" v-if="!$v.form.operacion_id.required">
                    Ingrese una operación
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" v-if="this.form.operacion_id">
                <div class="form-group">
                  <label for="producto">Producto</label>
                  <v-select
                    :class="[
                      $v.form.producto_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="producto"
                    placeholder="Producto"
                    label="nombre"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.productos"
                    :filterable="false"
                    @search="buscarProductos"
                    @input="selectProducto()"
                  ></v-select>
                  <div class="error" v-if="!$v.form.producto_id.required">
                    Ingrese un producto
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificación</label>
              <textarea
                v-model="form.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form.justificacion.required">
                Ingrese una Justificación
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="
                !$v.form.justificacion.$invalid &&
                  !$v.form.operacion_id.$invalid &&
                  !$v.form.producto_id.$invalid &&
                  !$v.form.ruta_id.$invalid
              "
              @click="cambioOperacion()"
            >
              Realizar Cambio
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Modal Cambio Horas -->
    <div
      class="modal fade"
      id="modal-form-horas-turno"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Horas Turno</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal8"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label for="fecha_final"> Fecha inicial:</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="formHoras.fecha_inicial"
                    :class="
                      $v.formHoras.fecha_inicial.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div div class="col-md-6">
                  <label for="hora_final"> Hora:</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="formHoras.hora_inicial"
                    :class="
                      $v.formHoras.hora_inicial.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99:99',
                      hourFormat: '23',
                    }"
                    @change="calcularHoras()"
                  />
                </div>
                <div class="col-md-6">
                  <label for="fecha_final"> Fecha final:</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="formHoras.fecha_final"
                    :class="
                      $v.formHoras.fecha_final.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div div class="col-md-6">
                  <label for="hora_final"> Hora:</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="formHoras.hora_final"
                    :class="
                      $v.formHoras.hora_final.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99:99',
                      hourFormat: '23',
                    }"
                    @change="calcularHoras()"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label for="calculo_horas">Duración:</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="formHoras.horas_turno"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.formHoras.$invalid"
              @click="saveCambioHoraTurno()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Modal Cambio Conductor-->
    <div
      class="modal fade"
      id="modal-form-cambio-conductor"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Conductor</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal4"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="conductor">Documento Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm col-md-12"
                    id="numero_documento"
                    readonly
                    v-model="form.conductor_documento"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehículo">Conductor Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm col-md-12"
                    id="conductor"
                    readonly
                    v-model="form.conductorAnt"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="numero_documento">Documento Sustituye</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="numero_documento"
                    :class="[
                      $v.form.conductor.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="form.conductorNuevo"
                    @change="buscarConductores()"
                  />
                  <div class="error" v-if="!$v.form.conductor.required">
                    Ingrese un Conductor
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="conductor">Conductor Sustituye</label>
                  <input
                    v-if="form.conductor"
                    type="text"
                    class="form-control form-control-sm col-md-12"
                    id="conductor"
                    readonly
                    v-model="form.conductor.nombre"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="
                !$v.form.justificacion.$invalid && !$v.form.conductor.$invalid
              "
              @click="cambioConductor()"
            >
              Realizar Cambio
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Modal Petición Tramas -->
    <div
      class="modal fade"
      id="modal-form-subir-tramas"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Petición Tramas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal5"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row justify-content-xl-center">
                <div class="col-md-12">
                  <div class="row">
                    <div class="card bg-light d-flex flex-fill">
                      <div class="card-body pt-3 ml-2">
                        <div class="row">
                          <div class="form-group col-md-3">
                            <label>Fecha inicial</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              style="font-size: 14px"
                              v-model="form.fecha_ini"
                              :class="
                                $v.form.fecha_ini.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              disabled
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label><i class="fas fa-clock ml-3"></i></label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              style="font-size: 15px"
                              v-model="form.hora_ini"
                              :class="
                                $v.form.hora_ini.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              v-mask="{
                                mask: '99:99:99',
                                hourFormat: '23',
                              }"
                              disabled
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label>Fecha final</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              style="font-size: 15px"
                              v-model="form.fecha_fin"
                              :class="
                                $v.form.fecha_fin.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              disabled
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label><i class="fas fa-clock ml-3"></i></label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              style="font-size: 15px"
                              v-model="form.hora_fin"
                              :class="
                                $v.form.hora_fin.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              v-mask="{
                                mask: '99:99:99',
                                hourFormat: '23',
                              }"
                              disabled
                            />
                          </div>
                          <div class="col-md-1 pt-4">
                            <div class="form-group">
                              <div
                                class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="encargado"
                                  v-model="form.encargado"
                                />
                                <label
                                  class="custom-control-label"
                                  for="encargado"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-1 mt-4">
                            <button
                              class="btn bg-navy"
                              v-show="
                                !$v.form.fecha_ini.$invalid &&
                                  !$v.form.fecha_fin.$invalid
                              "
                              @click="saveTramasPendientes()"
                            >
                              <i class="fas fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Finalizar Turno -->
    <div
      class="modal fade"
      id="modal-form-finalizacion-turno"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Finalizacion Turno</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal6"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label for="fecha_final"> Fecha final:</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="form_estado.fecha_final"
                    :class="
                      $v.form_estado.fecha_final.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div div class="col-md-6">
                  <label for="hora_final"> Hora:</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form_estado.hora_final"
                    :class="
                      $v.form_estado.hora_final.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99:99',
                      hourFormat: '23',
                    }"
                  />
                </div>
              </div>
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form_estado.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form_estado.justificacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form_estado.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_estado.$invalid"
              @click="saveFinalizarTurno()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Modal Cambio Vehiculo / Turno-->
    <div
      class="modal fade"
      id="modal-form-cambio-vehiculo-turno"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Vehículo / Turno</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehículo">Vehículo Actual</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="vehiculo"
                    readonly
                    v-model="form.vehiculoPlacaAnt"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="vehículo">Vehículo Sustituye</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="placa"
                    v-model="form.vehiculoPlacaNueva"
                    :class="[
                      $v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    @change="buscarCabezote()"
                  />
                  <div class="error" v-if="!$v.form.vehiculo.required">
                    Ingrese una Placa
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Fecha final</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  style="font-size: 12px"
                  v-model="form.fecha_fin"
                  disabled
                  :class="
                    $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-6">
                <label>Hora final</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  style="font-size: 12px"
                  v-model="form.hora_fin"
                  :class="$v.form.hora_fin.$invalid ? 'is-invalid' : 'is-valid'"
                  v-mask="{
                    mask: '99:99:99',
                    hourFormat: '23',
                  }"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              v-show="
                !$v.form.justificacion.$invalid &&
                  !$v.form.vehiculo.$invalid &&
                  !$v.form.fecha_fin.$invalid &&
                  !$v.form.hora_fin.$invalid
              "
              @click="cambioVehiculoTurno()"
            >
              Realizar Cambio
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, helpers } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import moment from "moment";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "TifVentanaGeneralOperacionesCambios",
  components: {
    vSelect,
  },
  data() {
    return {
      turno: {},
      operacion: null,
      producto: null,
      ruta: null,
      form_estado: {},
      formHoras: {
        fecha_inicial: null,
        hora_inicial: null,
        fecha_final: null,
        hora_final: null,
        horas_turno: 0,
      },

      form: {
        vehiculo: null,
        conductor: null,
        remolque: null,
        operacion_id: null,
        producto_id: null,
        ruta_id: null,
        justificacion: null,
        vehiculoPlacaAnt: null,
        remolquePlacaAnt: null,
        conductorAnt: null,
        operacionAnt: null,
        vehiculoPlacaNueva: null,
        conductorNuevo: null,
        remolqueNuevo: null,
        tipo_vehiculo_id: null,
        fechaIniTurno: null,
        fechaFinTurno: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        encargado: true,
        bloque: null,
      },
      listasForms: {
        operaciones: [],
        productos: [],
        rutas: [],
      },
    };
  },
  validations: {
    form_estado: {
      justificacion: {
        required,
        minLength: minLength(10),
      },
      fecha_final: {
        required,
      },
      hora_final: {
        required,
        timeFormat,
      },
    },
    form: {
      vehiculo: {
        required,
      },
      remolque: {
        required,
      },
      conductor: {
        required,
      },
      operacion_id: {
        required,
      },
      producto_id: {
        required,
      },
      ruta_id: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      hora_ini: {
        required,
        timeFormat,
      },
      hora_fin: {
        required,
        timeFormat,
      },
      justificacion: {
        required,
        minLength: minLength(10),
      },
    },
    formHoras: {
      fecha_inicial: {
        required,
      },
      hora_inicial: {
        required,
        timeFormat,
      },
      fecha_final: {
        required,
      },
      hora_final: {
        required,
        timeFormat,
      },
    },
  },
  methods: {
    cambioVehiculo() {
      this.$swal({
        title: "Esta seguro de cambiar este vehículo?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/tif/ventanasGeneralOperaciones/cambiarVehiculo?turno_id=" +
                this.form.turno_id,
              {
                vehiculo_id_ant: this.form.vehiculo_id,
                vehiculo_id_nueva: this.form.vehiculo.id,
                vehiculo_placa_ant: this.form.vehiculoPlacaAnt,
                vehiculo_placa_nueva: this.form.vehiculo.placa,
                justificacion: this.form.justificacion,
                turno_id: this.form.turno_id,
              }
            )
            .then((response) => {
              this.$refs.closeModal1.click();
              this.$parent.getIndex();
              this.form = {};
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    cambioVehiculoTurno() {
      this.$swal({
        title: "Esta seguro de cambiar este vehículo?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/tif/ventanasGeneralOperaciones/cambiarVehiculoTurno?turno_id=" +
                this.form.turno_id,
              {
                vehiculo_id_ant: this.form.vehiculo_id,
                vehiculo_id_nueva: this.form.vehiculo.id,
                vehiculo_placa_ant: this.form.vehiculoPlacaAnt,
                vehiculo_placa_nueva: this.form.vehiculo.placa,
                justificacion: this.form.justificacion,
                fecha_fin: this.form.fecha_fin,
                hora_fin: this.form.hora_fin,
                turno_id: this.form.turno_id,
                turno: this.turno,
              }
            )
            .then((response) => {
              this.$refs.closeModal1.click();
              this.$parent.getIndex();
              this.form = {};
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    cambioOperacion() {
      this.$swal({
        title: "Esta seguro de cambiar este operacion?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/tif/ventanasGeneralOperaciones/cambiarOperacion?turno_id=" +
                this.form.turno_id,
              {
                tif_operacion_id_ant: this.form.tif_operacion_id,
                tif_operacion_id_nuevo: this.form.operacion_id,
                operacion_nombre_ant: this.form.operacionAnt,
                operacion_nombre_nuevo: this.operacion.Noperacion,
                justificacion: this.form.justificacion,
                turno_id: this.form.turno_id,
                tif_producto_id_ant: this.form.tif_producto_id,
                tif_producto_id_nuevo: this.form.producto_id,
                producto_nombre_ant: this.form.productoAnt,
                producto_nombre_nuevo: this.producto.nombre,
                tif_ruta_id_ant: this.form.tif_ruta_id,
                tif_ruta_id_nuevo: this.form.ruta_id,
                ruta_nombre_ant: this.form.rutaAnt,
                ruta_nombre_nuevo: this.ruta.nombre,
              }
            )
            .then((response) => {
              this.$refs.closeModal3.click();
              this.$parent.getIndex();
              this.form = {
                vehiculo: null,
                conductor: null,
                remolque: null,
                operacion: null,
                vehiculoPlacaAnt: null,
                remolquePlacaAnt: null,
                conductorAnt: null,
                operacionAnt: null,
              };
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    cambioRemolque() {
      this.$swal({
        title: "Esta seguro de cambiar este remolque?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/tif/ventanasGeneralOperaciones/cambiarRemolque?turno_id=" +
                this.form.turno_id,
              {
                remolque_id_ant: this.form.remolque_id,
                remolque_id_nueva: this.form.remolque.id,
                remolque_placa_ant: this.form.remolquePlacaAnt,
                remolque_placa_nueva: this.form.remolque.placa,
                justificacion: this.form.justificacion,
                turno_id: this.form.turno_id,
              }
            )
            .then((response) => {
              this.$refs.closeModal2.click();
              this.$parent.getIndex();
              this.form = {
                vehiculo: null,
                conductor: null,
                remolque: null,
                operacion: null,
                vehiculoPlacaAnt: null,
                remolquePlacaAnt: null,
                conductorAnt: null,
              };
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    cambioConductor() {
      this.$swal({
        title: "Esta seguro de cambiar este conductor?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/tif/ventanasGeneralOperaciones/cambiarConductor?turno_id=" +
                this.form.turno_id,
              {
                conductor_id_ant: this.form.conductor_id,
                conductor_documento_ant: this.form.conductor_documento,
                conductor_nombre_ant: this.form.conductorAnt,
                conductor_celular_ant: this.form.conductor_celular,
                conductor_id_nueva: this.form.conductor.id,
                conductor_documento_nuevo: this.form.conductor.numero_documento,
                conductor_nombre_nuevo: this.form.conductor.nombre,
                conductor_celular_nuevo: this.form.conductor.celular_principal,
                justificacion: this.form.justificacion,
                turno_id: this.form.turno_id,
              }
            )
            .then((response) => {
              this.$refs.closeModal4.click();
              this.$parent.getIndex();
              this.form = {
                vehiculo: null,
                conductor: null,
                remolque: null,
                operacion: null,
                vehiculoPlacaAnt: null,
                remolquePlacaAnt: null,
                conductorAnt: null,
              };
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    async buscarCabezote() {
      let dato = this.form.vehiculoPlacaNueva;
      let fechaIni = this.form.fechaIniTurno;
      let fechaFin = this.form.fechaFinTurno;
      this.$parent.cargando = true;
      if (dato) {
        let res = await this.validarStatus(dato, "V", fechaIni, fechaFin);

        if (res && res.length != 0) {
          //Validación 2 horas de reporte gps
          //Se obtiene fecha actual

          if (res[0].ultimo_registro) {
            const fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
            //Se le resta 2 horas a la fecha actual
            const fechaCorte = moment(fechaAct)
              .add(-2, "hours")
              .format("YYYY-MM-DD HH:mm:ss");
            //Se obtiene la fecha de reporte del vehículo
            const fechaGps = moment(
              res[0].ultimo_registro.fecha_hora_ultimo_reporte
            ).format("YYYY-MM-DD HH:mm:ss");

            if (fechaCorte > fechaGps) {
              this.$parent.cargando = false;
              await this.$swal({
                text:
                  "El vehículo no ha reportado en las 2 últimas horas, FRONTERA debe autorizar..",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
              this.form.vehiculo = {
                id: res[0]["id"],
                placa: this.form.vehiculoPlacaNueva,
              };
              this.accion = 2;
            }
            this.$parent.cargando = false;
          } else {
            await this.$swal({
              title: "No es posible postular este vehículo!!",
              text: "El vehículo no tiene tramas satelitales a la fecha",
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            this.form.vehiculoPlacaNueva = null;
            this.form.vehiculo = {};
            this.$parent.cargando = false;
            return false;
          }

          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              this.form.vehiculoPlacaNueva = null;
              this.form.vehiculo = {};
              this.$parent.cargando = false;
              return false;
            }
          }

          //Validar si ya se encuentra con una inspección
          if (res.inspeccion != null) {
            if (res.inspeccion.estado == 4) {
              this.form.vehiculoPlacaNueva = null;
              this.form.vehiculo = {};
              await this.$swal({
                icon: "error",
                title: `La inspeccion del vehículo se encuentra en estado rechazado`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
              this.$parent.cargando = false;
              return false;
            } else if (res.inspeccion.estado == 5) {
              await this.$swal({
                icon: "success",
                title: `La inspeccion del vehículo se encuentra aprobada con novedad`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
              this.$parent.cargando = false;
              return true;
            } else if (res.inspeccion.estado == 2) {
              await this.$swal({
                icon: "success",
                title: `La inspeccion del vehículo se encuentra aprobada`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
            } else if (res.inspeccion.estado == 3) {
              await this.$swal({
                icon: "success",
                title: `La inspeccion del vehículo se encuentra autorizada`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
            }
          } else {
            this.form.vehiculoPlacaNueva = null;
            this.form.vehiculo = {};
            await this.$swal({
              icon: "error",
              title: `El vehículo no cuenta con inspección vigente`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          //Valida si se encuentra un turno activo
          if (res.turno.length > 0) {
            this.form.vehiculoPlacaNueva = null;
            this.form.vehiculo = {};
            await this.$swal({
              icon: "error",
              title: `El vehículo se encuentra activo dentro de un turno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          //Validar si ya se encuentra con una postulacion
          if (res.postulacion.length > 0) {
            this.form.vehiculoPlacaNueva = null;
            this.form.vehiculo = {};
            await this.$swal({
              icon: "error",
              title: `El vehículo cuenta con una postulacion activa`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          //Valida la información completa
          if (res["info"] == false) {
            await this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehiculos`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.form.vehiculoPlacaNueva = null;
            this.form.vehiculo = {};
            this.$parent.cargando = false;
            return false;
          }

          //Valida si se encuentra en la linea perteneciente
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              title: `El vehiculo no se encuentra en la linea de negocio Transportes Internos`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.form.vehiculoPlacaNueva = null;
            this.form.vehiculo = {};
            this.$parent.cargando = false;
            return false;
          }

          //Valida el tipo de vehiculo
          if (res[0].tipo_vehiculo_id != this.form.tipo_vehiculo_id) {
            this.form.vehiculoPlacaNueva = null;
            this.form.vehiculo = {};
            await this.$swal({
              icon: "error",
              title: `El tipo de vehículo no coincide.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            this.$parent.cargando = false;
            return false;
          }

          await this.$swal({
            icon: "success",
            title: "El vehículo es apto para ser postulado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          this.form.vehiculo = {
            id: res[0]["id"],
            placa: this.form.vehiculoPlacaNueva,
          };
        } else {
          await this.$swal({
            icon: "error",
            title: "La placa ingresada no pertenece a ningún vehículo",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.form.vehiculoPlacaNueva = null;
          this.form.vehiculo = {};
        }
      }
      this.$parent.cargando = false;
    },

    async buscarRemolque() {
      let dato = this.form.remolqueNuevo;
      let fechaIni = this.form.fechaIniTurno;
      let fechaFin = this.form.fechaFinTurno;
      this.$parent.cargando = true;
      if (dato) {
        let res = await this.validarStatus(dato, "R", fechaIni, fechaFin);
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            this.falgValidando = false;
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              this.form.remolqueNuevo = null;
              this.form.remolque = {};
              this.$parent.cargando = false;
              return false;
            }
          }

          //Valida la información completa
          if (res["info"] == false) {
            await this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creacion de Relmoques`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.form.remolqueNuevo = null;
            this.form.remolque = {};
            this.$parent.cargando = false;
            return false;
          }

          //Valida si se encuentra en la linea perteneciente
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              title: `El Remolque no se encuentra en la linea de negocio Transporte Interno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.form.remolqueNuevo = null;
            this.form.remolque = {};
            this.$parent.cargando = false;
            return false;
          }

          //Valida si se encuentra con un turno activo
          if (res.turno.length > 0) {
            this.form.remolqueNuevo = null;
            this.form.remolque = {};
            await this.$swal({
              icon: "error",
              title: `El remolque se encuentra activo dentro de un turno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          //Valida si se encuentra con una postulación activa
          if (res.postulacion.length > 0) {
            this.form.remolqueNuevo = null;
            this.form.remolque = {};
            await this.$swal({
              icon: "error",
              title: `El remolque cuenta con una postulacion activa`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }
          await this.$swal({
            icon: "success",
            title: "El remolque es apto para ser postulado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          this.form.remolque = {
            id: res[0]["id"],
            placa: this.form.remolqueNuevo,
          };
        } else {
          await this.$swal({
            icon: "error",
            title:
              "El remolque digitado no se encuentra registrado, pruebe con otra placa.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.form.remolqueNuevo = null;
          this.form.remolque = {};
        }
      }
      this.$parent.cargando = false;
    },

    async buscarConductores() {
      let dato = this.form.conductorNuevo;
      let fechaIni = this.form.fechaIniTurno;
      let fechaFin = this.form.fechaFinTurno;
      this.$parent.cargando = true;
      if (dato) {
        let res = await this.validarStatus(dato, "C", fechaIni, fechaFin);
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              this.form.conductorNuevo = null;
              this.form.conductor = {};
              this.$parent.cargando = false;
              return false;
            }
          }

          //Valida si se encuentra con la infomación completa
          if (res["info"] == false) {
            await this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.form.conductorNuevo = null;
            this.form.conductor = {};
            this.$parent.cargando = false;
            return false;
          }

          //Valida si se encuentra en la línea perteneciente
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              title: `El Conductor no se encuentra en la linea de negocio Transporte Interno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.form.conductorNuevo = null;
            this.form.conductor = {};
            this.$parent.cargando = false;
            return false;
          }

          //Valida si se encuentra con un turno activo
          if (res.turno.length > 0) {
            this.form.conductorNuevo = null;
            this.form.conductor = {};
            await this.$swal({
              icon: "error",
              title: `El conductor se encuentra activo dentro de un turno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          //Valida si se encuentra con una postulación activa
          if (res["postulacion"].length > 0) {
            this.form.conductorNuevo = null;
            this.form.conductor = {};
            await this.$swal({
              icon: "error",
              title: "El conductor cuenta con una postulacion activa",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          await this.$swal({
            icon: "success",
            title: "El conductor es apto para ser postulado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          this.form.conductor = {
            id: res[0]["id"],
            numero_documento: this.form.conductorNuevo,
            nombre: res[0]["nombres"] + " " + res[0]["apellidos"],
            celular_principal: res[0]["celular_principal"],
          };
          this.$parent.cargando = false;
          return true;
        } else {
          await this.$swal({
            icon: "error",
            title:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.form.conductorNuevo = null;
          this.form.conductor = {};
        }
      }
      this.$parent.cargando = false;
    },

    async msgDocumentacion(res) {
      this.$parent.cargando = false;
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
        }
        if (res.validacion.error.msg) {
          erroHtml += "<br><b>La entidad se encuentra inactiva.</b><br>";
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title: "Aviso en documentacion",
          showConfirmButton: false,
          showCloseButton: true,
          footer:
            "<small style='text-align:center'><b>Desde el 1 de septiembre se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas indata_solicitudacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
        });
      }
    },

    async validarStatus(dato, tipo, fechaIni, fechaFin) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/tif/postulaciones/buscarConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/tif/postulaciones/buscarRemolque";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/tif/postulaciones/buscarCabezote";
      }

      let params = {
        entidad,
        dato,
        fecha_ini: fechaIni,
        fecha_fin: fechaFin,
      };

      var res = null;

      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });

      return res;
    },

    buscarOperaciones(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "/api/tif/operaciones/lista?descripcion=" + search;
        axios
          .get(url, {
            params: {
              bloque_id: this.turno.bloque_id,
            },
          })
          .then(function(response) {
            me.listasForms.operaciones = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveCambioHoraTurno() {
      if (!this.$v.formHoras.$invalid) {
        axios({
          method: "PUT",
          url: "/api/tif/ventanasGeneralOperaciones/cambioHora",
          data: this.formHoras,
        })
          .then(() => {
            this.formHoras = {};
            this.$refs.closeModal8.click();
            this.$swal({
              icon: "success",
              title: "Se realizó el cambio de estado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        this.$parent.getIndex();
      }
    },

    calcularHoras() {
      let fecha_inicio = new Date(
        this.formHoras.fecha_inicial + " " + this.formHoras.hora_inicial
      ).getTime();
      let fecha_fin = new Date(
        this.formHoras.fecha_final + " " + this.formHoras.hora_final
      ).getTime();
      var diff = fecha_fin - fecha_inicio;
      var result = diff / (1000 * 60 * 60);
      let tiempo_punto = result.toFixed(2);

      this.formHoras.horas_turno = tiempo_punto;
    },

    buscarProductos(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "/api/tif/operaciones/opelistaProd?" + search;
        axios
          .get(url, {
            params: {
              operacion_id: this.form.operacion_id,
            },
          })
          .then(function(response) {
            me.listasForms.productos = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRutas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tif/rutas/lista?nombre=" + search;
        axios
          .get(url, {
            params: {
              bloque_id: this.turno.bloque_id,
            },
          })
          .then(function(response) {
            me.listasForms.rutas = response.data;
            loading(false);
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveFinalizarTurno() {
      if (!this.$v.form_estado.$invalid) {
        axios({
          method: "PUT",
          url: "/api/tif/ventanasGeneralOperaciones/finalizarTurno",
          data: this.form_estado,
        })
          .then(() => {
            this.form_estado = {};
            this.$refs.closeModal6.click();
            this.$swal({
              icon: "success",
              title: "Se realizó el cambio de estado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        this.$parent.getIndex();
      }
    },

    saveTramasPendientes() {
      let datos = {
        vehiculo_id: this.form.vehiculo_id,
        empresa_id: this.form.empresa_id,
        tif_turno_id: this.form.turno_id,
        fecha_ini: this.form.fecha_ini,
        hora_ini: this.form.hora_ini,
        fecha_fin: this.form.fecha_fin,
        hora_fin: this.form.hora_fin,
        encargado: this.form.encargado,
        estado: this.form.estado,
      };
      let data = { data: datos };

      this.$swal({
        title: "Esta seguro de crear esta solicitud de tramas?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar!!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "POST",
            url: "/api/tif/tramasPendientes",
            data: data,
          })
            .then((response) => {
              this.$refs.closeModal5.click();
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: "La petición se creo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    selectOperacion() {
      this.form.operacion_id = "";
      if (this.operacion) {
        this.form.operacion_id = this.operacion.id;
      }
    },

    selectProducto() {
      this.valTarifas();
      this.form.producto_id = "";
      if (this.producto) {
        this.form.producto_id = this.producto.id;
      }
    },

    selectRuta() {
      this.form.ruta_id = "";
      if (this.ruta) {
        this.form.ruta_id = this.ruta.id;
      }
    },

    limpiar() {
      this.producto = {};
      this.operacion = {};
      this.ruta = {};
      this.form.operacion_id = null;
      this.form.ruta_id = null;
      this.form.producto_id = null;
      this.form.justificacion = null;
    },

    valTarifas() {
      axios({
        method: "GET",
        url: "/api/tif/ventanasGeneralOperaciones/valTarifas",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.estado;
        if (this.validar == 2) {
          this.$swal({
            icon: "error",
            title: `No se puede realizar el cambio ya que no se encuentra una tarifa...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.operacion = {};
          this.form.operacion_id = null;
          this.form.producto = {};
          this.producto = {};
          this.operacion = {};
          this.form.justificacion = null;
        }
      });
    },

    llenar_modal_vehiculo(turno) {
      this.form.vehiculo_id = turno.vehiculo_id;
      this.form.vehiculoPlacaAnt = turno.vehiculo.placa;
      this.form.turno_id = turno.id;
      this.form.fechaIniTurno = turno.fecha_ini;
      this.form.fechaFinTurno = turno.fecha_fin;
      this.form.tipo_vehiculo_id = turno.tipo_vehiculo_id;
      this.form.vehiculoPlacaNueva = null;
      this.form.justificacion = null;
      this.form.vehiculo = {};
    },

    llenar_modal_vehiculo_turno(turno) {
      this.form.vehiculo_id = turno.vehiculo_id;
      this.form.vehiculoPlacaAnt = turno.vehiculo.placa;
      this.form.turno_id = turno.id;
      this.turno = turno;
      this.form.fecha_fin = turno.fecha_ini.slice(0, 10);
      this.form.fechaIniTurno = turno.fecha_ini;
      this.form.fechaFinTurno = turno.fecha_fin;
      this.form.tipo_vehiculo_id = turno.tipo_vehiculo_id;
      this.form.vehiculoPlacaNueva = null;
      this.form.justificacion = null;
      this.form.vehiculo = {};
    },

    llenar_modal_remolque(turno) {
      this.form.remolque_id = turno.remolque_id;
      this.form.remolquePlacaAnt = turno.remolque.placa;
      this.form.turno_id = turno.id;
      this.form.fechaIniTurno = turno.fecha_ini;
      this.form.fechaFinTurno = turno.fecha_fin;
      this.form.remolqueNuevo = null;
      this.form.justificacion = null;
      this.form.remolque = {};
    },

    llenar_modal_conductor(turno) {
      this.form.turno_id = turno.id;
      this.form.conductor_id = turno.conductor_id;
      this.form.conductor_documento = turno.conductor.numero_documento;
      this.form.conductorAnt = `${turno.conductor.nombres} ${turno.conductor.apellidos}`;
      this.form.conductor_celular = turno.conductor.celular_principal;
      this.form.fechaIniTurno = turno.fecha_ini;
      this.form.fechaFinTurno = turno.fecha_fin;
      this.form.conductorNuevo = null;
      this.form.justificacion = null;
      this.form.conductor = {};
    },

    llenar_modal_operacion(turno) {
      this.form.turno_id = turno.id;
      this.turno = turno;
      this.form.tif_operacion_id = turno.tif_operacion_id;
      this.form.operacionAnt = turno.operacion.Noperacion;
      this.form.tipo_tarifa = turno.tipo_tarifa;
      this.form.tipo_vehiculo = turno.tipo_vehiculo_id;
      this.form.tipo_turno = turno.turno_id;
      this.form.fecha_fin = turno.fecha_fin;
      this.form.tif_producto_id = turno.producto_id;
      this.form.productoAnt = turno.producto.nombre;
      this.form.bloque = turno.bloque.nombre;
      this.form.bloque_id = turno.bloque_id;
      this.form.empresa = turno.empresa_id;
      this.form.tif_ruta_id = turno.tif_ruta_id;
      this.form.rutaAnt = turno.ruta.nombre;
    },

    llenar_modal_tramas(turno) {
      this.form.turno_id = turno.id;
      this.form.fecha_ini = turno.fecha_ini.slice(0, 10);
      this.form.fecha_fin = turno.fecha_fin.slice(0, 10);
      this.form.hora_ini = turno.fecha_ini.slice(11, 19);
      this.form.hora_fin = turno.fecha_fin.slice(11, 19);
      this.form.vehiculo_id = turno.vehiculo_id;
      this.form.encargado = true;
      this.form.empresa_id = turno.empresa_id;
      this.form.estado = 1;
    },

    llenar_modal_finalizacion_turno(
      id,
      estado,
      estadoFinalizacion,
      mensaje,
      fecha_fin,
      fecha_ini
    ) {
      this.form_estado = {
        entidad: "Turnos",
        estado: estadoFinalizacion,
        entidad_id: id,
        id,
        valor_ant: estado,
        user: this.$store.state.user.id,
        fecha_final: moment(fecha_fin).format("YYYY-MM-DD"),
        hora_final: moment().format("HH:MM:SS"),
        fecha_inicial: fecha_ini,
      };
      this.mensaje = mensaje;
    },

    async llenar_modal_cambiar_horas_turno(id, mensaje, fecha_ini, fecha_fin) {
      this.formHoras = {
        id,
        fecha_inicial: moment(fecha_ini).format("YYYY-MM-DD"),
        hora_inicial: moment(fecha_ini).format("HH:MM:SS"),
        fecha_final: moment(fecha_fin).format("YYYY-MM-DD"),
        hora_final: moment(fecha_fin).format("HH:MM:SS"),
        horas_turno: 0,
      };
      this.mensaje = mensaje;
      this.calcularHoras();
    },
  },
};
</script>
